<template>
  <div class="card p-5">
    <div class="card__wrap mb-3">
      <h1 class="card-title mb-4 mr-3">Восстановление пароля</h1>
      <p class="card__subtitle">На ваш E-mail будет отправлен код</p>
    </div>

    <form @submit.prevent="onSubmit">
      <div class="card__input-wrap mb-4">
        <InputBlock v-model="email" label="E-mail" type="email" />
      </div>

      <div class="card__btn-wrap">
        <!-- <router-link to="/recovery-password/2/" class="btn btn-second mb-3"> -->
        <b-button type="submit" class="btn btn-second mb-3">
          <span v-if="!isDataSubmitting">Далee</span>
          <b-spinner v-else small></b-spinner>
        </b-button>
        <!-- </router-link> -->

        <router-link class="card__link" to="/registration">
          <h3 class="card__link-title">Регистрация</h3>
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import InputBlock from "./FormElement/Input";
import RequestManager from "../../function/request/RequestManager";
export default {
  name: "RecoveryForm",
  components: {
    InputBlock
  },
  data() {
    return {
      email: "",
      isDataSubmitting: false
    };
  },
  methods: {
    onSubmit() {
      const data = {
        email: this.email
      };
      this.isDataSubmitting = true;
      RequestManager()
        .requestReset(data)
        .then(response => {
          if (response.logError?.response?.status) {
            this.$bvToast.toast(
              `Ошибка отправки данных. Возможно, вы указали неверный Email`,
              {
                title: "Ошибка",
                solid: true,
                variant: "danger",
                autoHideDelay: 3000,
                appendToast: false
              }
            );
          } else {
            this.$emit("go-next", this.email);
          }
        })
        .finally(() => (this.isDataSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.card__subtitle {
  margin: 0;
  padding: 0;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.04em;

  color: #cfcfcf;
  text-align: center;
}

.card__btn-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card__link-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;

  color: #51a2be;
}
</style>
