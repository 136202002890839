<template>
  <TepmplateBlock modClass>
    <div class="row recovery-wrap">
      <RecoveryForm v-if="page === 0" @go-next="onNextClick" />
      <RecoveryAuth :email="email" v-else @goback="page = 0" />
    </div>
  </TepmplateBlock>
</template>

<script>
import RecoveryForm from "../../components/Form/RecoveryForm";
import RecoveryAuth from "./RecoveryAuth";
import TepmplateBlock from "../../components/TepmplateBlock";
export default {
  name: "RecoveryPassword",
  components: { TepmplateBlock, RecoveryForm, RecoveryAuth },
  data() {
    return {
      page: 0,
      email: ""
    };
  },
  methods: {
    onNextClick(email) {
      this.email = email;
      this.page = 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.recovery-wrap {
  width: 100%;
  max-width: 498px;
}
</style>
